<template>
  <th-page-wrapper>
    <div class="th-container">
      <el-row>
        <el-col type="flex" justify="space-between" :xl="12" :lg="12" :md="24">
          <div class="box-wrapper">
            <div class="put-away-box">
              <div class="multilevel-select mb10">
                <div>
                  {{ $t('pages.inventory.stocks.common.location.title') }}
                </div>
                <multilevel-select
                  v-model="selectedLocation"
                  :list="locations"
                  :placeholder-text="
                    $t('pages.inventory.stocks.common.location.placeholder')
                  "
                />
              </div>
              <div>
                <th-input-title
                  :title="
                    $t('pages.inventory.stocks.common.location.reason_title')
                  "
                />
                <th-resource-select
                  v-model="stock.selectedReason"
                  class="w-full"
                  resource="reasons"
                  :resource-query="{ deleted: false }"
                />
              </div>
              <div :class="['mb10', { 'disable-element': !selectedLocation }]">
                <item-display
                  v-if="stock.selectedItem"
                  :item="itemToDisplay"
                  @clear-selected-item="clearSelectedItem"
                />
                <scan-panel
                  v-else
                  v-model="itemToDisplay.id"
                  :disabled="!selectedLocation"
                  :list="stock.productsList"
                  :is-loading-items="loading"
                  @fetch-list="fetchProducts"
                  @update:modelValue="selectProduct"
                />
              </div>
              <div
                :class="[
                  'mb10',
                  {
                    'disable-element': !selectedLocation,
                    opacity: !selectedLocation
                  }
                ]"
              >
                <numpad v-model="stockQty" :disabled="!stock.selectedItem" />
              </div>
              <el-button
                class="book-button"
                :disabled="
                  !selectedLocation || !stock.selectedItem || bookingLoading
                "
                @click="bookStock"
              >
                {{ $t('pages.inventory.stocks.goods_out.book_stock.title') }}
              </el-button>
              <el-alert
                v-show="
                  stock.selectedItem && itemToDisplay.current_stock == null
                "
                class="warning"
                :title="
                  $t(
                    'pages.inventory.stocks.goods_out.book_stock.no_current_stock.warning'
                  )
                "
                type="warning"
                effect="dark"
              />

              <!-- V2 -->
              <router-link
                :to="{
                  name: 'ims-stock-manager',
                  query: { tab: 'goods-book-out' }
                }"
                class="block mt-4 mb-4"
              >
                <el-button plain>
                  {{ $t('common.forms.labels.new_flow') }}
                </el-button>
              </router-link>
            </div>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="24">
          <div class="history-box">
            <goods-out-history
              :history-list="goodsOutHistory"
              :title="$t('pages.inventory.stocks.goods_out.history.title')"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <multi-products-dialog
      :products="multipleProducts"
      :visible="multipleProductsModal"
      @select-product="selectProductInModal"
      @cancel-dialog="cancelDialog"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import debounce from 'debounce'
import MultilevelSelect from '@/components/multilevel-select'
import Numpad from '../components/numpad'
import ItemDisplay from '../components/item-display'
import ScanPanel from '../components/scan-panel'
import GoodsOutHistory from '../components/stock-actions-history'
import MultiProductsDialog from '../components/multi-products-dialog'
import makeBarcodeReader from '../helpers/makeBarcodeReader'
import { formatLabel, findById } from '../helpers'

const barcodeReader = makeBarcodeReader()

function getInitialData() {
  return {
    selectedReason: null,
    selectedItem: undefined,
    qty: 0,
    productsList: []
  }
}

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.inventory.goods_out')
    }
  },
  components: {
    MultilevelSelect,
    Numpad,
    ItemDisplay,
    ScanPanel,
    GoodsOutHistory,
    MultiProductsDialog
  },
  data() {
    return {
      stock: getInitialData(),
      selectedLocation: undefined,
      locations: [],
      goodsOutHistory: [],
      loading: false,
      bookingLoading: false,
      multipleProductsModal: false,
      multipleProducts: [],
      productStockInfo: []
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    productLocationStock() {
      return this.productStockInfo.find(
        (stock) => stock.location.id === this.selectedLocation
      )
    },
    itemToDisplay() {
      return {
        ...this.stock.selectedItem,
        current_stock: safeGet(this.productLocationStock, 'qty')
      }
    },
    stockQty: {
      get() {
        return this.stock.qty
      },
      set(v) {
        const newQty = Number(v)
        if (Number.isFinite(newQty)) {
          return (this.stock.qty = newQty)
        }
      }
    }
  },
  watch: {
    currentLocation() {
      this.setDefaultSelectedLocation()
    }
  },
  created() {
    document
      .querySelector('body')
      .addEventListener('keydown', this.barcodeListener)
  },
  async mounted() {
    await this.fetchLocations()
    this.setDefaultSelectedLocation()
  },
  beforeUnmount() {
    document
      .querySelector('body')
      .removeEventListener('keydown', this.barcodeListener)
  },
  methods: {
    setDefaultSelectedLocation() {
      const locationExists = !!this.locations.find(
        ({ id }) => id === this.currentLocation
      )
      this.selectedLocation = locationExists ? this.currentLocation : null
    },
    async fetchProducts(product) {
      if (product.length < 3) return (this.stock.productsList = [])

      try {
        this.loading = true
        const { data = {} } = await th
          .products()
          .search({ q: product, stockable: true })
        if (data.starts_with) {
          this.stock.productsList = data.starts_with.map((item) => ({
            id: item.id,
            label: formatLabel(item),
            name: item.name,
            barcode: item.barcode,
            custom_id: item.custom_id
          }))
        }
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    getProductDetails: debounce(
      async function () {
        try {
          const { data } = await th
            .products()
            .getDetails(safeGet(this.stock, 'selectedItem.id'), {
              original_product: true
            })
          this.productStockInfo = safeGet(data, 'all_stock') || {}
        } catch (err) {
          this.$logException(err, { trackError: false })
        }
      },
      300,
      true
    ),
    selectProduct(product) {
      if (product) {
        const oldProductId = safeGet(this.stock, 'selectedItem.id')
        this.stock.selectedItem = product
        if (oldProductId !== safeGet(this.stock, 'selectedItem.id')) {
          this.getProductDetails()
        }
      }
    },
    async fetchLocations() {
      try {
        const { data = [] } = await th
          .stocks()
          .getLocations({ query: { deleted: false } })
        this.locations = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    async bookStock() {
      const successMessage = this.$t(
        'pages.products.detail_view.messages.stock_update.success'
      )
      const errorMessage = this.$t(
        'pages.products.detail_view.messages.stock_update.error'
      )

      const location = this.selectedLocation
      const locationName = safeGet(findById(this.locations, location), 'name')
      const qty = this.stockQty
      const qtyToSubtract = Math.abs(qty) * -1
      const reason = this.stock.selectedReason || undefined

      try {
        this.bookingLoading = true

        const reqOptions = {
          productId: safeGet(this.stock, 'selectedItem.id'),
          body: {
            reason,
            location,
            qty: qtyToSubtract
          }
        }

        await th.products().bookStock(reqOptions)

        this.goodsOutHistory.unshift({
          ...this.stock.selectedItem,
          qty,
          location: locationName
        })
        this.stock = getInitialData()
        this.productStockInfo = []

        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage,
          trackError: false
        })
      } finally {
        this.bookingLoading = false
      }
    },
    cancelDialog() {
      this.multipleProducts = []
      this.multipleProductsModal = false
    },
    clearSelectedItem() {
      this.stock = getInitialData()
    },
    selectProductInModal(product) {
      this.selectProduct({
        id: product.id,
        barcode: product.barcode,
        name: product.name,
        label: product.name,
        custom_id: product.custom_id
      })
      this.stockQty = 1
      this.multipleProductsModal = false
    },
    barcodeListener(e) {
      barcodeReader.handleBarcode(e, this.handleBarcode)
    },
    async handleBarcode(barcode) {
      if (!this.selectedLocation) {
        return this.$message({
          type: 'error',
          message: this.$t('pages.inventory.stocks.scan_panel.scan.disabled')
        })
      }

      if (barcode && barcode.length < 6) {
        this.stock = getInitialData()
        return this.$message({
          type: 'error',
          message: this.$t('pages.inventory.stocks.errors.invalid_barcode')
        })
      }

      let qty = 1
      if (
        this.stock.selectedItem &&
        safeGet(this.stock, 'selectedItem.barcode') === barcode
      ) {
        // if the same product has already been scanned - increase qty by 1
        qty = this.stockQty + 1
      } else {
        let results

        try {
          const { data = {} } = await th.products().search(barcode)
          results = data.starts_with
        } catch (err) {
          return this.$message({
            type: 'error',
            message: this.$t('pages.inventory.stocks.errors.no_product')
          })
        }

        if (!results || !Array.isArray(results) || !results.length) {
          return this.$message({
            type: 'error',
            message: this.$t('pages.inventory.stocks.errors.no_product')
          })
        }

        if (results.length > 1) {
          this.multipleProductsModal = true
          this.multipleProducts = results
          return
        }

        const product = {
          id: results[0].id,
          barcode: results[0].barcode,
          name: results[0].name,
          label: results[0].name,
          custom_id: results[0].custom_id
        }

        this.selectProduct(product)
      }
      this.stockQty = qty
    }
  }
}
</script>

<style scoped>
.th-container {
  margin-top: 7rem;
}

.box-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.put-away-box {
  width: 25rem;
}

.history-box {
  display: flex;
  justify-content: center;
}

.multilevel-select {
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-button {
  background-color: #279ff6;
  color: white;
  width: 100%;
  font-size: 1.2rem;
}

.el-button:hover {
  background-color: #279ff6;
  color: white;
}

.mb10 {
  margin-bottom: 10px;
}

.disable-element {
  pointer-events: none;
}

.opacity {
  opacity: 0.2;
}

.warning {
  margin-top: 1.6rem;
}

@media (max-width: 1380px) {
  .th-container {
    margin-top: 2rem;
  }

  .box-wrapper {
    align-items: center;
    margin-bottom: 5rem;
  }
}

@media (max-width: 600px) {
  .put-away-box {
    width: 16rem;
  }
}
</style>
